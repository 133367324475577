.row{
    margin-left: 20px;
    color: #fff;
    
}
.posters{
    display: flex;
    padding: 20px; 
    overflow-x:scroll;
    overflow-y: hidden;

}
.posters::-webkit-scrollbar{
    display:none;
}

.poster{
    cursor :pointer;
    max-height: 250px;
    margin-right: 10px;

}
.poster:hover{
    transform:scale(1.05) ;
}

.smallPoster{
    max-height: 190px;
    margin-right: 10px;
    max-width:250px;
}
.smallPoster:hover{
    transform:scale(1.09) ;
}