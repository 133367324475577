
.navbar{

    padding: 20px;
    background-color: #111;
    height:30px;
    position: fixed;
    display:flex;
    justify-content: space-between;
    top:0;
    width:100%
    
}

.logo{
    position: fixed;
    left: 20px;
    width: 80px;
}
.avatar{
    position: fixed;
    right: 20px ;
    width:30px

}
h4{
    color: white;
    padding-left: 2000px;
}
.navbar-home{
    display: flex;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


}
.core{
    padding-left:120px;
}
.core1{
    padding-left: 50px;

}
.core2{
    padding-left: 50px;

}
.core3{
    padding-left: 50px;

}
.core4{
    padding-left: 50px;

}
.core5{
    padding-left: 50px;

}